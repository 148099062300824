@font-face {
  font-family: 'NotoSans';
  src: url('NotoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url('NotoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
