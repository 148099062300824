/* 100vh minus header */
@media(min-width: 768px) {
    .md\:h-screen-wo-header {
        height: calc(100vh - 80px);
    }
}

/* Background - gradient. */
.bg-primary-gradient {
    background-image: linear-gradient(116deg, #6abec4 0%, #4991cb 100%);
    /* Auth: background-image: linear-gradient(137deg, #6abec4 0%, #4991cb 100%); */
}
