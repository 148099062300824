.active svg,
.active span {
    color: rgba(26, 146, 208, var(--tw-text-opacity));
    fill: rgba(26, 146, 208, var(--tw-text-opacity));
    stroke-width: 2;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.fadeIn {
    animation: fadeIn 0.2s;
}