.customTable td,
.customTable th {
    border-left-width: 1px;
    border-top-width: 1px;
    border-right: none;
    border-bottom: none;
}

.customTable th {
    border-width: 1px;
    border-top: none;
}

.customTable td:first-child,
.customTable th:first-child {
    border-left: none;
}
