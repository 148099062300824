@font-face {
  font-family: 'Avenir';
  src: url('Avenir-Light.eot');
  src: url('Avenir-Light.eot?#iefix') format('embedded-opentype'),
       url('Avenir-Light.svg#Avenir-Light') format('svg'),
       url('Avenir-Light.ttf') format('truetype'),
       url('Avenir-Light.woff') format('woff'),
       url('Avenir-Light.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('Avenir-Book.eot');
  src: url('Avenir-Book.eot?#iefix') format('embedded-opentype'),
       url('Avenir-Book.svg#Avenir-Book') format('svg'),
       url('Avenir-Book.ttf') format('truetype'),
       url('Avenir-Book.woff') format('woff'),
       url('Avenir-Book.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('Avenir-Roman.eot');
  src: url('Avenir-Roman.eot?#iefix') format('embedded-opentype'),
       url('Avenir-Roman.svg#Avenir-Roman') format('svg'),
       url('Avenir-Roman.ttf') format('truetype'),
       url('Avenir-Roman.woff') format('woff'),
       url('Avenir-Roman.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('Avenir-Medium.eot');
  src: url('Avenir-Medium.eot?#iefix') format('embedded-opentype'),
       url('Avenir-Medium.svg#Avenir-Medium') format('svg'),
       url('Avenir-Medium.ttf') format('truetype'),
       url('Avenir-Medium.woff') format('woff'),
       url('Avenir-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('Avenir-Heavy.eot');
  src: url('Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
       url('Avenir-Heavy.svg#Avenir-Heavy') format('svg'),
       url('Avenir-Heavy.ttf') format('truetype'),
       url('Avenir-Heavy.woff') format('woff'),
       url('Avenir-Heavy.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('Avenir-Black.eot');
  src: url('Avenir-Black.eot?#iefix') format('embedded-opentype'),
       url('Avenir-Black.svg#Avenir-Black') format('svg'),
       url('Avenir-Black.ttf') format('truetype'),
       url('Avenir-Black.woff') format('woff'),
       url('Avenir-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

